import http from "@/utils/axios.js";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router/index.js";

export default {
  namespaced: true,
  state: {
    patient: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirm: "",
      code: "",
      phone_number: "",
      date: "",
      pesel: "",
      isNoPesel: false,
      country_code: null,
      country: null,
      national_number: null,
      accept_terms: false,
      accept_data_processing: false,
      agree_contact: false,
      phone_is_valid: false,
    },
    doctor: {
      code: "",
      first_name: "",
      last_name: "",
      main_medical_category_id: null,
      pwz: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirm: "",
      accept_terms: false,
      accept_marketing: false,
      accept_data_processing: false,
      agree_contact: false,
      commission_option: null,
      country_code: null,
      country: null,
      national_number: null,
      phone_is_valid: false,
    },
    admin: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirm: "",
      country_code: null,
      country: null,
      national_number: null,
      phone_is_valid: false,
    },
    gatekeeper: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirm: "",
      country_code: null,
      country: null,
      national_number: null,
      phone_is_valid: false,
    },
    errors: [],
    commissionOptions: ["d15p0", "d10p5", "d5p10", "d0p15", "other"],
  },
  getters: {
    getPatient: (state) => state.patient,
    getErrors: (state) => state.errors,
    getDoctor: (state) => state.doctor,
    getAdmin: (state) => state.admin,
    getGatekeeper: (state) => state.gatekeeper,
    getCommissionOptions: (state) => state.commissionOptions,
  },
  mutations: {
    setPatient(state, patient) {
      Object.assign(state.patient, patient);
    },
    setDoctor(state, doctor) {
      Object.assign(state.doctor, doctor);
    },
    setAdmin(state, admin) {
      Object.assign(state.admin, admin);
    },
    setGatekeeper(state, gatekeeper) {
      Object.assign(state.gatekeeper, gatekeeper);
    },
    resetPatient(state) {
      state.patient = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        code: "",
        phone_number: "",
        date: "",
        pesel: "",
        isNoPesel: false,
        country_code: null,
        country: null,
        national_number: null,
        accept_terms: false,
        accept_data_processing: false,
        agree_contact: false,
        phone_is_valid: false,
      };
    },
    resetDoctor(state) {
      state.doctor = {
        code: "",
        first_name: "",
        last_name: "",
        main_medical_category_id: null,
        pwz: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirm: "",
        accept_terms: false,
        accept_marketing: false,
        accept_data_processing: false,
        agree_contact: false,
        commission_option: null,
        country_code: null,
        country: null,
        national_number: null,
        phone_is_valid: false,
      };
    },
    resetAdmin(state) {
      state.admin = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirm: "",
        country_code: null,
        country: null,
        national_number: null,
        phone_is_valid: false,
      };
    },
    resetGatekeeper(state) {
      state.gatekeeper = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        password: "",
        password_confirm: "",
        country_code: null,
        country: null,
        national_number: null,
        phone_is_valid: false,
      };
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    clearDoctor(state) {
      Object.keys(state.doctor).forEach((key) => (state.doctor[key] = ""));
      state.doctor.main_medical_category_id = null;
      state.doctor.accept_terms = false;
      state.doctor.accept_data_processing = false;
      state.doctor.agree_contact = false;
      state.doctor.accept_marketing = false;
      state.doctor.commission_option = null;
    },
  },
  actions: {
    async loginDoctor({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/doctor/login", state.doctor)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            sessionStorage.removeItem("cart");
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            commit("clearDoctor");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 403)
              router.push({
                name: "Blocked",
              });
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async loginAdmin({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/admin/login", state.admin)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            sessionStorage.removeItem("cart");
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            commit("clearAdmins");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 403)
              router.push({
                name: "Blocked",
              });
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async loginGatekeeper({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/gatekeeper/login", state.gatekeeper)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            sessionStorage.removeItem("cart");
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            commit("clearGatekeepers");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 403)
              router.push({
                name: "Blocked",
              });
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async registerDoctor({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/doctor/register", state.doctor)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async verifyDoctor({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/register/verify", { code: state.doctor.code })
          .then(({ data }) => {
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async resendCodeDoctor() {
      await http
        .post("/register/send-verification-token")
        .then(() => {
          notify({
            type: "success",
            text: "messages.code.resend",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 404) {
            notify({
              type: "danger",
              text: "messages.code.resend_error",
              duration: 5000,
            });
          }

          if (response && response.status === 429) {
            notify({
              type: "danger",
              text: response.data.message,
              duration: 5000,
            });
          }
        });
    },
    async loginPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/login", state.patient)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            sessionStorage.removeItem("cart");
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async registerPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/send-verification-token", state.patient)
          .then(({ data }) => {
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async verifyPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/verify-code", state.patient)
          .then(() => {
            commit("clearErrors");
            resolve();
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
    async resendCodePatient({ state }) {
      await http
        .post("/patient/send-verification-token", state.patient)
        .then(() => {
          notify({
            type: "info",
            text: "messages.code.resend",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            notify({
              type: "danger",
              text: "messages.code.resend_error",
              duration: 5000,
            });
        });
    },
    async firstLoginPatient({ commit, state }) {
      return new Promise((resolve, reject) => {
        return http
          .post("/patient/first-login", state.patient)
          .then(({ data }) => {
            localStorage.authtoken = data.token;
            http.defaults.headers["Authorization"] = "Bearer " + data.token;
            commit("clearErrors");
            resolve(data);
          })
          .catch(({ response }) => {
            if (response && response.status === 422)
              commit("setErrors", response.data.errors);
            reject();
          });
      });
    },
  },
};
